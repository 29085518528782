import React from 'react';
import {toast} from 'react-toastify';
import Input from './Input';
import Button from './Button';
import request from '../modules/request';
import {
  Wrapper,
  Label,
  Textarea
} from './ContactForm.styles';

const ContactForm = () => {
  const handleSubmit = async event => {
    event.preventDefault();

    const name = document.querySelector('input[name="name"]').value;
    const email = document.querySelector('input[name="email"]').value;
    const message = document.querySelector('textarea[name="message"]').value;

    await request('email', {
      name,
      email,
      message
    }, false);

    toast.success('Message sent!', {
      position: toast.POSITION.TOP_CENTER
    });

    // reset form
    document.querySelector('input[name="name"]').value = '';
    document.querySelector('input[name="email"]').value = '';
    document.querySelector('textarea[name="message"]').value = '';
  };

  return (
    <Wrapper>
      <form onSubmit={handleSubmit}>
        <Label>Name *</Label>
        <Input name="name" required />
        <Label>Email *</Label>
        <Input name="email" type="email" required />
        <Label>Message *</Label>
        <Textarea name="message" required />
        <Button type="submit">Send</Button>
      </form>
    </Wrapper>
  );
};

export default ContactForm;
