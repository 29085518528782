import styled from 'styled-components/macro';
import {gray} from '../modules/colors';

export const Wrapper = styled.div`
  padding: 40px;

  input {
    margin-bottom: 30px;
  }

  button {
    margin-top: 30px;
  }
`;

export const Label = styled.label`
  margin: 0px 0 10px 0;
  display: block;
`;

export const Textarea = styled.textarea`
  width: 100%;
  height: 140px;
  border: 1px solid #DDE7EF;
  color: ${gray};
`;
