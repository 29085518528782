import React from 'react';
import {Link} from 'gatsby';
import styled from 'styled-components/macro';
import Layout from '../components/Layout';
import ContactForm from '../components/ContactForm';
import SectionHeader from '../components/SectionHeader';
import Card from '../components/Card';
import {pink} from '../modules/colors';
import media from '../modules/media';

const Wrapper = styled.div`
  padding: 40px;
`;

const Container = styled.div`
  display: flex;

  > div {
    width: 50%;
  }

  p {
    margin-top: 0;
    margin-bottom: 30px;
  }

  a {
    color: ${pink};
  }

  ${media.tablet`
    flex-direction: column;

    > div {
      width: 100%;
      box-sizing: border-box;
    }
  `};
`;

const ContactPage = () => (
  <Layout>
    <Wrapper>
      <SectionHeader>Contact Us</SectionHeader>
      <Container>
        <Card>
          <h4>Question? Problem? Feedback?</h4>
          <p>Talk to us and we will get back to you as soon as possible!</p>
          <h4>Looking for support?</h4>
          <p>
            Maybe the <Link to="/documentation">documentation</Link> is what you are looking for? There you can find
            examples on how to use GraphQLsheet and your GraphQL APIs.
          </p>
          <p>
            You can also find us on{' '}
            <a href="https://twitter.com/graphQLsheet" rel="noopener noreferrer" target="_blank">
              Twitter
            </a>
            !
          </p>
        </Card>
        <ContactForm />
      </Container>
    </Wrapper>
  </Layout>
);

export default ContactPage;
